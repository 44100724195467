.socialLink {
    height: 40px;
    margin: 0 8px
}

.socialLink:hover {
    opacity: 50%;
    height: 50px;
}

a {
    text-decoration: none !important;
    color: black;
}

.navLink {
    margin: 0 0.5vw;
    font-size: 1.2em;
}

.card-hover:hover {
    transform: scale(1.09);
}