@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
/* Reset and General Styling */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  min-height: 100vh;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App Container Styling */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

/* Custom Font Classes */
.cursive-font {
  font-family: 'Dancing Script', cursive;
}

.uppercase-font {
  font-family: 'Staatliches', cursive;
}

.text-left {
  text-align: left;
}

/* Image Styling */
.center-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.socialLink {
    height: 40px;
    margin: 0 8px
}

.socialLink:hover {
    opacity: 50%;
    height: 50px;
}

a {
    text-decoration: none !important;
    color: black;
}

.navLink {
    margin: 0 0.5vw;
    font-size: 1.2em;
}

.card-hover:hover {
    transform: scale(1.09);
}
